@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

body {
  font-family: "Roboto", sans-serif;
  background-color: #f8f9fe !important;
  font-size: 16px;
}

.App {
  text-align: center;
  scroll-behavior: smooth;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg-p {
  background-color: #f8f9fe !important;
}

.table-shadow {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}


.card-shadow2 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.votingChip {
  position: relative;
  transition: ease 0.2s;
  border-radius: 100%;
  overflow: hidden;
}

.votingChip:hover {
  /* box-shadow: 0 5px 5px gray; */
  transform: translatey(-6px);
}

.chipText {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: bold;
}

.flipCard {
  width: 130px;
  height: 160px;
  transition: transform 1s;
  transform-style: preserve-3d;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
}

.backCard,
.frontCard {
  position: absolute;
  width: 130px;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;

}

.backCard {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
}


.pokerCard {
  margin: 0 10px 0;
}

.pokerCard img {
  width: 130px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 15px,
    rgba(0, 0, 0, 0.22) 0px 8px 15px;
}

.topCardVote {
  position: absolute;
  color: white;
  top: 10px;
  left: 20px;
  font-weight: bold;
}

.bottomCardVote {
  position: absolute;
  color: white;
  bottom: 10px;
  right: 20px;
  font-weight: bold;
}


.scrollable-div::-webkit-scrollbar {
  width: 8px;
  transition: width 1s;
}

.scrollable-div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(216, 216, 216);
}

.scrollable-div::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: #b1b3c2;
  /* background-color: #44a2ff; */
  /* -webkit-box-shadow: inset 0 0 6px rgba(90, 90, 90, 0.7); */
}



.scrollable-div2::-webkit-scrollbar {
  width: 12.5px;
  transition: width 1s;
}

.scrollable-div2::-webkit-scrollbar-track {
  border-radius: 0px;
}

.scrollable-div2::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgb(197, 197, 197);
}

.itemExtend {
  height: 500px;
}

.pokerResultTable {
  margin: 10px auto;
  background-color: #4a7a3f;
  color: #efefef;
}

.pokerResultTable th {
  text-align: center;
  border: 1px solid #efefef;
}

.pokerResultTable td {
  padding: 5px 10px;
  border: 1px solid #efefef;
  text-align: center;
}

.pokerResultTable tr {
  text-align: left;
  border: 1px solid #efefef;
}

.votelbl {
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#container {
  display: flex;
  gap: 20px;
  top: 10%;
}

#chart {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
}

canvas {
  cursor: pointer;
}

#wheel-container {
  position: relative;
  width: 450px;
  height: 450px;
  margin-left: 100;
  justify-self: end;
}

#arrow-image-container {
  width: 30px;
  position: absolute;
  z-index: 10;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
}

#arrow-image-container>img {
  width: 100%;
}

.top-spacer {
  position: absolute;
  display: flex;
  align-items: center;
  height: 90vh;
  margin-left: 22vw;
  justify-content: center;
}

#message {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  font-size: 36px;
  font-weight: 800;
  color: white;
  cursor: pointer;
  text-align: center;
}

.spinarrow {
  width: 60px;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  cursor: pointer;
  overflow: hidden;
}

.edit_icon {
  cursor: pointer;
}

.maincard {
  transition: 0.1s;
  overflow: hidden;
  text-align: center;
}

.maincard:hover {
  transform: scale(1.02);
}


.card3 {
  border: solid #e1e4e7 1px;
  border-radius: 15px;
  padding: 1.5rem;
  width: 20vw;
  color: #4a515f;
}

.bg-img {
  height: 100vh;
  display: block;
}

.modal-content {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
}

.starIcon {
  font-size: 25px;
  cursor: pointer;
  margin-right: 5px;
}

.square-item {
  transition: 0.2s;
}

.square-item:hover {
  /* transform: translatey(-10px); */
  transform: scale(1.05);
}

.card-shadow-hover{
  cursor: pointer;
  transition: 0.2s;
}
.card-shadow-hover:hover{
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}
.crown-hover:hover {
  color: #ffd700 !important;
  position: relative;
}

.piechart {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background-color: white;
}

.popup-animitation {
  transition: 0.5s;
}

.disabled-div {
  pointer-events: none;
  opacity: 0.5;
}

.drag-area {
  /* pointer-events: none; */
}

@media only screen and (max-width: 800px) {

  .card2 {
    flex-direction: column;
    width: 75vw;
  }

  .prising {
    flex-direction: column;
    align-items: center;
  }

  #container {
    flex-direction: column;
  }

  .respons {
    flex-direction: column;
    gap: 20px;
  }

  .font-for-phone {
    font-size: 8px;
  }

  /* d-flex justify-content-center align-items-center */
  .table-col-responsive {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 4px;
    margin-top: 3px;
    gap: 10px;
    margin-left: 5px;
  }

  .pre-next-btn-responsve {
    display: flex;
    justify-content: space-between;
    width: 100vw;
  }

  .next-btn-responsvie {
    margin-bottom: 80px;
  }

  .sidebar-upgrade {
    width: 100% !important;
    left: 0px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .bg-img-retro {
    /* width: auto !important; */
  }
}

.item {
  transition: opacity 0.5s ease;
}

.item-entering,
.item-exiting {
  opacity: 0;
}

/* [draggable=true] {
  cursor: grab;
}
[draggable=true]:active {
  cursor: grabbed;
} */
[draggable=true] {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAiFBMVEUAAABmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlI1D3AAAADnRSTlMAAAAAAABupGE3AAAAV0lEQVR42mJ0jAhRgAqH///z/vnDEjFgop1IyOOrIQ8YIaDpVMpRiJcAelXQIIabqVzgJIXiVkAGgFhVaaLnh58AAAAAElFTkSuQmCC') 8 0, grab;
}

[draggable=true]:active {
  cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAmFBMVEUAAABmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZmZlI1D3AAAADnRSTlMAAAAAAABupGE3AAAAV0lEQVR42mJ0jAhRgAqH///z/vnDEjFgop1IyOOrIQ8YIaDpVMpRiJcAelXQIIabqVzgJIXiVkAGgFhVaaLnh58AAAAAElFTkSuQmCC') 8 0, grabbing;
}

[draggable=true] button {
  cursor: pointer;
  /* Change cursor for buttons inside draggable items */
}

.sidebar {
  width: 250px;
  /* height: calc(100vh-80px); */
  height: calc(100vh - 50px);
  overflow-y: auto;
  background-color: #35353a;
  position: fixed;
  top: 0;
  left: -250px;
  transition: left 0.3s;
  padding: 20px;
  /* border-radius: 12px; */
  overflow-x: hidden;
  z-index: 1;
}

.sidebar-upgrade {
  border: none;
  width: 240px;
  overflow-y: auto;
  position: fixed;
  bottom: 0;
  left: -250px;
  transition: ease 0.3s;
  padding: 10px;
  overflow-x: hidden;
  z-index: 1;
  background-color: #10a37f;
  /* background-color: #00b9a0; */
  color: white;
  border: solid #10a37f 1px;
}

.sidebar-upgrade.open {
  left: 0;
}

.sidebar-upgrade:hover {
background-color: #1a7f64;
}

.sidebar.open {
  left: 0;
}

.sidebar-toggle {
  cursor: pointer;
  padding: 10px;
  margin: 8px;
  position: absolute;
  top: 5px;
  left: 210px;
  z-index: 1;
}

.sidebar-toggle:hover {
  transform: scale(1.1);
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  /* Remove top margin */
}

.sidebar-menu li {
  text-align: left;
  /* Align list items to the left */
}

.sidebar-menu a {
  font-size: small;
  text-decoration: none;
  color: white;
  font-size: 18px;
  display: block;
  padding: 5px 0;
  /* Add padding to each item */
}

/* .sidebar-menu a:hover {
  color: blue;
} */

.navbar-brand-img {
  max-width: 100%;
  max-height: 2rem;
}


hr.horizontal {
  border-top: none;
  height: 1px;
}

hr.vertical {
  position: absolute;
  background-color: transparent;
  height: 100%;
  right: 0;
  top: 0;
  width: 1px;
}

hr.vertical.light {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0));
}

hr.vertical.dark {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);
}

hr.vertical.gray-light {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

hr.horizontal {
  background-color: transparent;
}

hr.horizontal.light {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), #fff, rgba(255, 255, 255, 0));
}

hr.horizontal.dark {
  background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.4), transparent);
}

hr.horizontal.gray-light {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
}

.Dashboard-link {
  /* margin: 0 !important; */
  padding: 0px 0px !important;
}

.card2 {
  background: transparent;
  border-radius: 7px;
  /* border: 1px solid transparent; */
  padding: 3px 15px;
  transition: ease 0.2s;
  width: 100%;
  cursor: pointer;
  font-size: 17px;
}

.pricing-card {
  /* background: transparent; */
  border-radius: 7px;
  padding: 4px 15px;
  transition: ease 0.2s;
  width: 100%;
  cursor: pointer;
  font-size: 17px;
  margin: 20px;
}

.listPage-transition {
  transition: 0.3s;
}

.card2:hover {
  background-color: #6c757d !important;
  /* border: 1px solid #6c757d; */
}

.card2.active {
  background-color: #3892ee;
  border: 1px solid #3892ee;
}

.side-margin {
  margin-left: 270px !important;
}

.sideNav-top {
  margin-top: 58px;
}

.accoutpages {
  margin: 3px 0;
}

.navbar {
  z-index: 1;
}

.navbar-toggler-resuable {
  display: block !important;
}

.margin-top {
  margin-top: 8rem;
}

@media (max-width: 768px) {
  .sidebar {
    width: 90px;
  }

  .sidebar .navbar-brand-img {
    display: none;
  }

  .sidebar .card2>i {
    display: inline-block;
  }

  .sidebar .card2>span {
    display: none;
  }

  .card2 {
    width: 100%;
    padding: 8px 12px;
  }

  .side-margin {
    margin-left: 80px;
  }

  .side-text {
    font-size: 12px;
  }

  .agilebin {
    margin: 0;
  }
}

/* CSS */
.circular-overlay {
  position: absolute;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(240, 240, 240, 0.8);
  color: #333;
  font-size: 14px;
  overflow: hidden;
}

.line-between {
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  top: 50%;
  transform: translateY(-1px);
}

.top-overlay {
  top: 0;
  background-color: black;
  opacity: 0.6;
  border-bottom: solid black 2px;
  transition: ease 0.2s;
}

.bottom-overlay {
  bottom: 0;
  background-color: black;
  opacity: 0.6;
  border-bottom: solid black 2px;
  transition: ease 0.2s;
}

.top-overlay:hover {
  opacity: 0.7;
}

.bottom-overlay:hover {
  opacity: 0.7;
}

span,
b,
label {
  font-size: 16px;
}

.bg-image .selected {
  border: 2px solid #3498db;
}

.votingtbg {
  /* position: relative; */
  width: 100%;
  /* height: 100vh; */
  /* Adjust the height as needed */
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10vh;
}

.semi-card {
  box-sizing: content-box;
  padding: 1.5rem;
  background-color: rgba(37, 37, 37, 0.0);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
}

.content {
  position: relative;
  z-index: 1;
  padding: 20px;
  width: 80vw;
}

.pro {
  position: relative;
  top: 0;
  left: 11%;
  display: inline-block;
  transform: translate(-50%, -50%);
  padding: 5px;
  font-size: 11px;
  background: #ffd43b;
  color: black;
  border-radius: 5px;
}

.pro-custom {
  position: relative;
  display: inline-block;
  padding: 5px;
  font-size: 11px;
  background: #ffd43b;
  border-radius: 5px;
}

.pro-poker {
  display: inline-block;
  transform: translate(-50%, -50%);
  padding: 5px;
  font-size: 11px;
  background: #ffd43b;
  color: white;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  left: 70px;
  opacity: 100%;
  z-index: 1;
}

.pro-template-card {
  position: absolute;
  display: flex;
  padding: 5px 10px;
  font-size: 11px;
  background: #ffd43b;
  border-radius: 5px;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 100%;
  z-index: 0;
}

.pro-image {
  position: relative;
}

.pro-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.446);
  /* Adjust color and alpha for the faded effect */
}

.pro-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: gold;
  /* Adjust the text color */
  font-size: 18px;
  /* Adjust the font size */
  font-weight: bold;
}

.point {
  cursor: pointer;
}

/* ---------------------------Agilebin Css ----------------------------- */


p {
  font-size: 16px;
  margin: 0%;
}

hr {
  padding: 0px !important;
  margin: 5px !important;
}

a {
  text-decoration: none;
}



input[type=checkbox] {
  /* Double-sized Checkboxes */
  -ms-transform: scale(1.5);
  /* IE */
  -moz-transform: scale(1.5);
  /* FF */
  -webkit-transform: scale(1.5);
  /* Safari and Chrome */
  -o-transform: scale(1.5);
  /* Opera */
  transform: scale(1.5);
  padding: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

/*Extend bootstrap classes*/

.img-fluid {
  width: 100%
}

/*Common classes*/
.required::after {
  content: "*";
  font-weight: bold;
  color: red;
}

/*Styles for fist of five*/

.votelbl:hover {
  transform: scale(1.05);
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
  max-width: 800px;
  max-height: 90vh;
  overflow: auto;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/*Styles for Retrospective*/

.cat1 {
  margin: 5px;
  float: left;
  width: 45%;
  height: 165px;
  padding: 10px;
  position: relative;
  overflow: hidden;
  /* background-color: yellow; */
  /* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

/*Styles for poker game*/

.chip {
  justify-content: center;
  align-items: center;
  display: inline-flex;
  background: url(../public/images/chip.png);
  width: 60px;
  height: 60px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  color: #ffffff;
  font-size: large;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Times New Roman', Times, serif;
}

.chip:hover {
  transform: translateY(-4px);
  color: #ffffff;
}

.chip:active {
  transform: translateY(4px);
}

.profile-container-wrapper {
  display: flex;
  align-items: center;
}

.profile-picture-container {
  width: 31px;
  /* Set your desired width */
  height: 31px;
  /* Set your desired height */
  overflow: hidden;
  border-radius: 50%;
  /* Make it circular */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00b9a0;
  font-size: 6px;
  /* Adjust the font size as needed */
  z-index: 1;
}

.profile-picture-item {
  width: 20px;
  /* Set your desired width */
  height: 20px;
  /* Set your desired height */
  overflow: hidden;
  border-radius: 50%;
  /* Make it circular */
  color: white;
  display: flex;
  background-color: #00b9a0;
  font-size: 6px;
  /* Adjust the font size as needed */
  z-index: 1;
  align-self: flex-end;
  display: inline-block;
  padding: 0;
  margin: 0;
}

.profile-p-p {
  display: flex;
  align-items: center;
  justify-content: center;
}

.module-border-wrap {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  position: absolute;
  background: linear-gradient(to right, red, purple);
  padding: 3px;
  z-index: 1;
}

.module-border-wrap2 {
  width: 114px;
  height: 114px;
  border-radius: 50%;
  position: absolute;
  background: linear-gradient(to right, red, purple);
  padding: 3px;
}

.profile-letter {
  padding-top: 12px;
}

.profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;

}

.modal-content-confirmation {
  width: 650px;
}

.template-card-body {
  z-index: 0;
  background-color: rgb(255, 255, 255);
  height: 45px;
  position: absolute;
  bottom: 0px;
  width: 100%;
  color: rgb(61, 61, 61);
  display: flex;
  padding: 1rem;
  font-weight: 540;
}

.btn-plus-minus {
  background-color: white;
  border: 2px solid rgb(90, 90, 90);
  border-radius: .75rem;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: .75rem 1.2rem;
  text-align: center;
  text-decoration: none #6B7280 solid;
  text-decoration-thickness: auto;
  transition-duration: .2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(.4, 0, 0.2, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  transition: ease 0.2s;
}



.btn-plus-minus:hover {
  color: #0d6efd;
  border: #0d6efd solid 2px;
  transform: scale(1.05);
}

.pricing-number-input {
  width: 100px !important;
  padding: 0 20px;
}

.sizemin {
  height: 21px;
  width: 21px;
}


.side-scroll-bar::-webkit-scrollbar {
  width: 12px;
}

.side-scroll-bar::-webkit-scrollbar-track {
  background: transparent;
}

.side-scroll-bar::-webkit-scrollbar-thumb {
  background-color: rgb(70, 70, 70);
  border-radius: 20px;
  border: 3px solid rgb(0, 0, 0);
}


/* ------------------- Circle Button ------------------ */
.circleIconContainer {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: rgb(229, 229, 229);
  border-radius: 50%;
  cursor: pointer;
}

.circleIcon {
  position: absolute;
  top: 54%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 26px;
  color: black;
}

.circleIconContainer:hover {
  background-color: lightgray;
}

/* pricing table css  */
.pricing-table {
  margin-top: 50px;
}

.pricing-column {
  padding: 20px;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  background-color: #fff;
}


.pricing-column p {
  font-size: 18px;
  margin-bottom: 15px;
}


.btn-signup {
  background-color: #5cb85c;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.btn-signup:hover {
  background-color: #4cae4c;
}

.ul-pricing {
  list-style: none;
  padding-left: 0px;

}

.ul-pricing li {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  list-style-position: outside;
}

.ul-pricing li:before {
  content: '✓ ';
}

.custom-subscription-card {
  max-width: 550px;
}

.price-display {
  font-size: 2rem;
}

.quantity-input {
  text-align: center;
}

.subscribe-button {
  margin-top: 20px;
}

.toggle-switch {
  position: relative;
}

.toggle-label {
  cursor: pointer;
}

.toggle-label.active {
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #65b997;
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

.round {
  border-radius: 34px;
}

.fancy-card {
  background-color: #f5f5f5;
  padding: 0rem;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(206, 175, 0, 0.521);
  border: rgba(206, 175, 0, 0.521) solid 2px;
  padding-bottom: 1rem;
  /* border: rgba(226, 192, 0, 0.658); */
}

.fancy-card .card-body {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.fancy-card h4 {
  color: #333;
}

.toggle-label {
  cursor: pointer;
}

.toggle-label.active {
  font-weight: bold;
}

.toggle-switch {
  display: flex;
  align-items: center;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.no-spinners::-webkit-inner-spin-button,
.no-spinners::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-spinners {
  -moz-appearance: textfield;
}

.tab-pricing-right-btn {
  border: none;
  border-top-right-radius: 18px;
}

.tab-pricing-left-btn {
  border: none;
  border-top-left-radius: 18px;
}

.outer-card {
  padding: 1.7rem;
  margin-bottom: 1.2rem;
  border-radius: 7px;
  /* background-color: transparent !important; */
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
  /* box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px; */
}

.showmorebutton {
  color: #657078 !important;
  transition: 0.2s ease;
}

.showmorebutton:hover {
  color: black;
}

.showsettings {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.showsettings.show {
  max-height: 250px;
  overflow: auto;
}



.column-header:hover {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.table-header {
  background-color: black;
}

.pagination {
  margin-bottom: 0 !important;
}

.option-select {
  padding: 7px 0px;
}

.option-select:hover {
  background-color: rgba(193, 221, 255, 0.544);
}

.open-sans-400 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

.open-sans-600 {
  font-family: "Open Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}



@keyframes scaleIn {
  from {
    transform: scale(0);
    transform-origin: left top;
  }
  to {
    transform: scale(1);
    transform-origin: left top;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
    transform-origin: left top;
  }
  to {
    transform: scale(0);
    transform-origin: left top;
  }
}




.noteColorSelector:hover {
  transform: scale(1.1);
}

.popperBtn {
  cursor: pointer;
  padding: 10px;
}

.popperBtn:hover {
  background-color: #e3eaff;
}

.disabled {
  background-color: rgb(249, 249, 249);
  pointer-events: none;
  color: rgb(171, 171, 171);
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}


@keyframes timerOver {
  0% { background-color: transparent; }
  50% { background-color: black; }
  100% { background-color: transparent; }
}

.timerending-animination{
  animation: timerOver 1s infinite;
}
.blinking-text {
  animation: blink 1s infinite;
  color: red !important;
  transform: scale(1.2);
}

@keyframes dash {
  to {
      background-position: 100% 0%, 0% 100%, 0% 0%, 100% 100%;
  }
}


.drop-here {
  background: linear-gradient(90deg, rgb(120, 120, 120) 50%, transparent 50%), 
              linear-gradient(90deg, rgb(142, 142, 142) 50%, transparent 50%), 
              linear-gradient(0deg, rgb(129, 129, 129) 50%, transparent 50%), 
              linear-gradient(0deg, rgb(144, 144, 144) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-x, repeat-y, repeat-y;
  background-size: 12px 4px, 12px 4px, 4px 12px, 4px 12px; /* Adjusted size for thicker borders */
  background-position: 0% 0%, 100% 100%, 0% 100%, 100% 0px;
  border-radius: 5px;
  animation: dash 15s linear infinite;
}

.highlighted-number {
  font-size: 16px; 
}

@keyframes border-dance {
  0% {
    background-position: 0px 0px, 300px 116px, 0px 150px, 216px 0px;
  }
  100% {
    background-position: 300px 0px, 0px 116px, 0px 0px, 216px 150px;
  }
}

.messeg-box {
  position: relative;
}

.arrow-up {
  position: absolute;
  top: -10px;
  right: 70px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff; /* Change the color based on your mode */
  z-index: 1;
}

/* Adjust the arrow color based on the mode */
.messeg-box.text-dark .arrow-up {
  border-bottom-color: #fff;
}

.messeg-box.text-white .arrow-up {
  border-bottom-color: #333;
}


/* Accordion Summary */
.MuiAccordionSummary-root {
  background-color: #e0e0e0;
  border-bottom: 1px solid #d0d0d0;
  transition: background-color 0.3s ease;
}

.MuiAccordionSummary-root:hover {
  background-color: #e6e6e6;
}

/* Accordion Details */
.MuiAccordionDetails-root {
  padding: 0;
  display: flex;
  flex-direction: column;
}

/* List Items */
.MuiListItem-root {
  padding: 0;
}

.MuiListItemButton-root {
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.MuiListItemButton-root:hover {
  background-color: #e0f7fa;
}

.MuiListItemIcon-root {
  min-width: 40px;
}

.MuiListItemText-root {
  margin: 0;
}

.MuiTypography-root {
  font-size: 14px;
}

.AccordionSidebar {
  padding: 5px 0px; /* Adjust padding as needed */
}

/* src/HorizontalScrollCard.css */
.horizontal-scroll-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-left: 60px;
}

.scrollable-cards {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: calc(100% - 80px); /* Adjust based on button width */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  gap: 40px;
}

.scrollable-cards::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.card {
  min-width: 200px;
  margin: 10px;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  flex: 0 0 auto;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #efefeff5; /* Slightly transparent for a softer look */
  color: black;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s, transform 0.3s;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.scroll-button:hover {
  background-color: rgb(187, 187, 187);
}


.fade-effect {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  z-index: 0;
  pointer-events: none;
}


.fade-effect.right {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

/* src/HorizontalScrollCard.css */
.horizontal-scroll-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-left: 60px;
}

.scrollable-cards {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  width: calc(100% - 80px); /* Adjust based on button width */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  gap: 40px;
}

.scrollable-cards::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.card {
  min-width: 200px;
  margin: 10px;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 10px;
  text-align: center;
  flex: 0 0 auto;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #efefeff5; /* Slightly transparent for a softer look */
  color: black;
  border: none;
  border-radius: 50%;
  padding: 15px;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s, transform 0.3s;
}

.scroll-button.left {
  left: 0;
}

.scroll-button.right {
  right: 0;
}

.scroll-button:hover {
  background-color: rgb(187, 187, 187);
}


.fade-effect {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 40px;
  z-index: 0;
  pointer-events: none;
}


.fade-effect.right {
  right: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
}

.faq-container {
  padding: 80px 0;
}

.faq-item {
  padding: 2rem;
  cursor: pointer;
  margin-bottom: 20px;
  background-color: white;
  border-radius: 10px;
}


.faq-icon-circle .material-symbols-outlined {
  color: #ffffff;
  font-size: 18px;
  line-height: 0;
}
.faq-icon-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007bff;
}

.faq-answer {
  display: none;
}

.faq-answer.show {
  display: block;
  margin-top: 25px;
}